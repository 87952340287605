
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

// import { LoginComponent } from './login';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { EnvService } from '@app/_services/environment.service'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
// import { UserInputTextComponent } from './user-input-text/user-input-text.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { InCellTrashRenderer } from './billing/in-cell-trash-renderer.componet';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from './shared/shared.module';
import { WebcamModule } from 'ngx-webcam';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ProgressBatHttpInterceptor } from './_helpers/progressb.interceptor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InLineAssistanceModule } from './in-line-assistance/in-line-assistance.module';
import { WindowCloseButtonModule } from './appcommonuicomponents/window-close-button/window-close-button.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SharedDirectivesModule } from './appdirectives/shared-directives/shared-directives.module';
import { MessageBoxModule } from './message-box/message-box.module';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { PatientModule } from './patient/patient.module';
import { EmailSenderDialogComponent } from './email-sender-dialog/email-sender-dialog.component';
import { MenuPopupModule } from './app-popup-module/app-popup-module';
import { FdAlertModule  as FDToastModule} from 'fd-alerts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SvgModule } from './svg/svg.module';
import { OpenViduAngularConfig, OpenViduAngularModule } from 'openvidu-angular';
import { environment } from './video-call/video-call-environment';
// import { VideoCallModule } from './video-call/video-call.module';
// import { VideoCallComponent } from './video-call/video-call.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashScreenModule } from './splash-screen/splash-screen.module';
import { SupportChatModule } from './support-chat/support-chat.module';
import { TrainingRecorderModule } from './training-recorder/training-recorder.module';

const config: OpenViduAngularConfig = {
  production: environment.production,
};


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    // BrowserAnimationsModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    MatSnackBarModule,
    // TO INVESTIGATE : Commenting this module out breaks application
    //PatientModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: true,
          pedantic: false,
          sanitize: true,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatProgressBarModule,
    // NOW: MatSidenavModule,
    MatSlideToggleModule,
    MatTableModule,
    // MatTabsModule,
    MatProgressSpinnerModule,
    MatSelectModule,

    // WebcamModule,
    MenuPopupModule,
    // MatCheckboxModule,
    InLineAssistanceModule,
    WindowCloseButtonModule,
    SharedDirectivesModule,
    WindowCloseButtonModule,
    MatSelectModule,
    MessageBoxModule,
    SvgModule,
    // ChatItemModule,
    // using specific options with ValueProvider and passing HttpClient
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    FDToastModule,
    AngularSvgIconModule.forRoot(),
    SplashScreenModule,
    SupportChatModule,
    TrainingRecorderModule
    

    //OpenViduAngularModule.forRoot(config),
    // VideoCallModule
    
  ],
  declarations: [
    //VideoCallComponent,
    InCellTrashRenderer,
    AppComponent,
    // UserInputTextComponent,
    EmailSenderDialogComponent,
    // IcdCodeFormComponent
    // LoginComponent,
    // ChangePasswordComponent,
    // ChangeEmailComponent,
    // ConfirmDeleteUserComponent,
    //CreateFormComponent,
    //CreateFormFieldComponent,
    //FormFieldViewComponent,
    //FormComponent,
  ],
  providers: [
    //{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressBatHttpInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: DatePipe },
    { provide: APP_INITIALIZER, useFactory: (envService: EnvService) => () => envService.init(), deps: [EnvService], multi: true },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
  ],
  exports: [ 
    // VideoCallComponent
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

