import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConsultType } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-manage-consult-types',
  templateUrl: './manage-consult-types.component.html',
  styleUrls: ['./manage-consult-types.component.scss']
})
export class ManageConsultTypesComponent implements OnInit, OnDestroy {

  public consultTypes$: Observable<ConsultType[]> = of([]);
  public activeConsultType: ConsultType = new ConsultType();
  public colorOptions = ["Pink", "White", "Green", "Yellow", "Turquoise", "Orange", "Black", "Red", "DodgerBlue", "MediumSeaGreen", "Coral", "SlateBlue", "Goldenrod", "CadetBlue", "Tomato", "SteelBlue", "Peru", "DarkOrchid", "DeepSkyBlue", "LightCoral", "MediumPurple", "DarkSalmon", "LightGreen", "SandyBrown", "Plum", "DarkTurquoise", "Sienna", "Orchid"];

  constructor(private api: ApiInterfaceService, private webApi: WebApiInterfaceService) { }

  ngOnInit(): void {
    this.consultTypes$ = this.fetchConsultTypes();
  }

  public get canSaveConsultType(): boolean {
    return this.activeConsultType && hasValue(this.activeConsultType?.name);
  }

  public createNewConsult() {
    this.activeConsultType = new ConsultType();
  }

  private fetchConsultTypes(): Observable<ConsultType[]> {
    return this.webApi.getEntities<ConsultType>(new ConsultType());
  }

  public deleteConsultType(): void {
    this.api.deleteEntity<ConsultType>(new ConsultType(), this.activeConsultType.id).subscribe(() => {
      const deletetedConsultTypeId = this.activeConsultType.id;
      this.consultTypes$ = this.consultTypes$.pipe(map(data => data.filter(x => x.id !== deletetedConsultTypeId)));
    })
  }

  public saveConsultType(): void {
    if (this.activeConsultType.id) {
      this.updateConsultType();
    } else {
      this.createNewConsultType();
    }
  }

  private createNewConsultType() {
    this.api.createEntity<ConsultType>(new ConsultType(), this.activeConsultType).subscribe((savedConsultType) => {
      this.consultTypes$ = this.consultTypes$.pipe(map(data => [savedConsultType, ...data]));
      this.activeConsultType = new ConsultType();
    });
  }

  private updateConsultType() {
    this.api.updateEntity<ConsultType>(new ConsultType(), this.activeConsultType).subscribe((savedConsultType) => {
      this.consultTypes$ = this.consultTypes$.pipe(map(data => {
        let indexOf = data.indexOf(this.activeConsultType);
        data[indexOf] = this.activeConsultType;
        return data;
      }));
      this.activeConsultType = new ConsultType();
    });
  }

  ngOnDestroy() {
    // Edits in this page will effect preload data so force reload
    this.api.forcePreLoadDataReload();
  }

}

function hasValue(obj: any): boolean {
  return (obj != null || obj != undefined) && obj !== '';
}