import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, Observable, share, throwError } from 'rxjs';
import { enableProdMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpFileTopic, InLineAssistanceHelpTopic, InLineAssistanceItem, ReleaseNoteItem } from '@app/in-line-assistance/in-line-assistance.service';
import { HttpClient } from '@angular/common/http';
import { HealthCheckItemExtended } from '@app/_alchemint/alchemint_composite_requests';
import { ApiKeys } from '@app/_alchemint/alchemint_dm';
import { HelperFunctions } from '@app/_helpers/helperfunctions';
import { HelpFileModuleAdvanced } from '@app/help-file/help-file.component';


export enum Environment {
  Prod,
  Test,
  Dev,
  Staging
}

export class DeployedEnvironment {
  production: boolean;
  apiUrl: string;
  apiAuthUrl: string;
  apiStatsUrl: string;
  apiWebUiUrl: string;
  apiExtendedUrl: string;
  artifactServiceEndPoint: string;
  publicContentEndPoint: string;
  publicContentAccessKey: string;
  apiBiUrl: string;
  helpdDeskSignalRUrl: string;
  portalApiUrl: string;
}


@Injectable({ providedIn: 'root' })
export class EnvService {

  public specificDate: string;

  public orgNameBehaveSubject$ : BehaviorSubject<string> = new BehaviorSubject<string> ('');

  private _inLineAssistanceItems :  InLineAssistanceItem[];
  inLineAssistanceItems(): InLineAssistanceItem[] 
  {
    return this._inLineAssistanceItems;
  }
  private _inLineAssistanceHelpTopics: InLineAssistanceHelpTopic[];
  inLineAssistanceHelpTopics(): InLineAssistanceHelpTopic[] 
  {
    return this._inLineAssistanceHelpTopics;
  }

  private _helpFileTopics: HelpFileTopic[];
  helpFileTopics(): HelpFileTopic[] 
  {
    return this._helpFileTopics;
  }

  
  _helpFileTopicsAdvanced: HelpFileModuleAdvanced[]
  helpFileTopicsAdvanced(): HelpFileModuleAdvanced []
  {
    return this._helpFileTopicsAdvanced;
  }
  
  private _releaseNoteItems: ReleaseNoteItem[];
  releaseNoteItems(): ReleaseNoteItem[] 
  {
    return this._releaseNoteItems;
  }

  
  
  public getAlchemedInLineHelp (): Observable<InLineAssistanceItem[]>
  {
    return this.http.get<InLineAssistanceItem[]>('assets/alchemedhelp.en.json')
    .pipe(catchError((err) => { return this.HandleError(err);})).pipe();  
  }

  public getAlchemedInLineHelpTopics (): Observable<InLineAssistanceHelpTopic[]>
  {
    return this.http.get<InLineAssistanceHelpTopic[]>('assets/alchemedhelptopics.en.json')
    .pipe(catchError((err) => { return this.HandleError(err);})).pipe();  
  }

  public getAlchemedHelpFileTopics (): Observable<HelpFileTopic[]>
  {
    return this.http.get<HelpFileTopic[]>('assets/alchemedwebapphelp.json')
    .pipe(catchError((err) => { return this.HandleError(err);})).pipe();  
  }

  public getAlchemedHelpFileTopicsAdvanced (): Observable<HelpFileModuleAdvanced[]>
  {
    var dateTime: Date = new Date();
    
    return this.http.get<HelpFileModuleAdvanced[]>('assets/alchemedwebapphelpadvanced.json' + `?dt=${dateTime.toISOString()}`)
    .pipe(catchError((err) => { return this.HandleError(err);})).pipe();  
  }

  


  public getAlchemedReleaseNoteItems (): Observable<ReleaseNoteItem[]>
  {
    return this.http.get<ReleaseNoteItem[]>('assets/alchemedreleaseitems.json')
    .pipe(catchError((err) => { return this.HandleError(err);})).pipe();  
  }

  
  public getUnassistedHelper (): Observable<HealthCheckItemExtended[]>
  {
    return this.http.get<HealthCheckItemExtended[]>('assets/UnassistedHelper.json')
    .pipe(catchError((err) => { return this.HandleError(err);})).pipe(share());  
  }

  private HandleError (err : any) : Observable<never>
  {
    if (err?.statusText)
    {
      console.log(err.statusText);
    }
    else
    {
      console.log("Missing error status");
      console.log(err);
    }
    
    //alert(msg);
    return throwError(err);    //Rethrow it back to component
  }

  private _env: Environment;
  private _apiUrl: string;
  private _deploymentSettings: DeployedEnvironment;

  private isBetaCheckComplete: boolean = false;
  private isRunningInBeta: boolean = false;

  private _httpErrorCount: number = 0;

  public set httpErrorCount(value: number) {
    this._httpErrorCount = value;
    if (this._httpErrorCount > 30) {
      this.appPaused = true;
      this._httpErrorCount = 0;
    }
  }
  public get httpErrorCount(): number {
    return this._httpErrorCount;
  }

  private _appPaused: boolean = false;

  public get appPaused(): boolean {
    return this._appPaused;
  }
  public set appPaused(value: boolean) {
    this._appPaused = value;
  }

  constructor(private router: Router, private http : HttpClient) {
    var currentHref = window.location.href;
    this.isRunningInBeta = (currentHref.indexOf('/beta/') > 0);
    if (this.isRunningInBeta) {
      console.log('Running Beta Mode');
    }

  }


  public overrideEnvironmentCoreServerUri(uri: string) {
    if (this._deploymentSettings.apiUrl !== uri)
    {
      this._deploymentSettings.apiUrl = uri;
      this._deploymentSettings.apiAuthUrl = uri.replace('api/', 'user');
      localStorage.setItem('apiUrl', this._deploymentSettings.apiUrl);
      localStorage.setItem('authUrl', this._deploymentSettings.apiAuthUrl );
    }
  }

  public get areLocalStoredSettingsForServerPresent () : boolean
  {
    return (localStorage.getItem('apiUrl') != null);
  }

  public get localStoredApiUrl () : string
  {
    return localStorage.getItem('apiUrl');
  }

  public get localStoredApiAuthUrl () : string
  {
    return localStorage.getItem('authUrl');
  }

  public localStoredApiWebUiUrl () : string
  {
    return localStorage.getItem('apiWebUiUrl');
  }

  public localStoredApiExtendedUrl () : string
  {
    return localStorage.getItem('apiExtendedUrl');
  }

  public localStoredApiPortalUrl () : string
  {
    return localStorage.getItem('apiPortalUrl');
  }

  


  public overrideEnvironmentAuxillaryServerUri(uri: string) {
    if (this._deploymentSettings.apiWebUiUrl != uri)
    {
      this._deploymentSettings.apiWebUiUrl = uri;
      this._deploymentSettings.apiExtendedUrl = uri + 'apiext/';
      localStorage.setItem('apiWebUiUrl', this._deploymentSettings.apiWebUiUrl);
      localStorage.setItem('apiExtendedUrl', this._deploymentSettings.apiExtendedUrl);
    }
  }








  get deploymentSettings(): DeployedEnvironment {
    return this._deploymentSettings;
  }

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  public isAlphaSiteOrEnvironment(apiKey: ApiKeys) : boolean {
    var ret : boolean = 
    (apiKey?.includeAlphaFetures === true) || 
    // (this.env === Environment.Dev) || 
    (this.env === Environment.Staging) || 
    (this.env === Environment.Test);
    return ret;
  }
  
  public isBetaSiteOrEnvironment(apiKey: ApiKeys) : boolean {
    var ret : boolean = 
    (apiKey?.includeBetaFetures === true) || 
    // (this.env === Environment.Dev) || 
    (this.env === Environment.Staging) || 
    (this.env === Environment.Test);
    return ret;
  }
  


  init(): Promise<void> {
    return new Promise(resolve => {
      if (this.setEnvVariables() == false) {
        alert(`Unsupported environment`);
      }
      else {
        try {


          // var obs1$ = this.getAlchemedInLineHelp;
          // var obs2$ = this.getAlchemedInLineHelpTopics;

          var sub = forkJoin(
            this.getAlchemedInLineHelp(),  this.getAlchemedInLineHelpTopics(), this.getAlchemedHelpFileTopics(), this.getAlchemedReleaseNoteItems(), this.getAlchemedHelpFileTopicsAdvanced()
          ).subscribe(
            ([helpDetail, helpTopics, helpFileTopics, releaseNoteItems, helpFileAdvanced]) => {
              this._inLineAssistanceItems = helpDetail; 
              this._inLineAssistanceHelpTopics = helpTopics; 
              this._helpFileTopics = helpFileTopics;
              this._releaseNoteItems = releaseNoteItems;
              this._helpFileTopicsAdvanced = helpFileAdvanced;
              resolve();
            },
            err => { 
              alert(err.message);
              resolve();
             
            }
          );
      


          // this.getAlchemedInLineHelp().subscribe(
          //   data => {
          //     this._inLineAssistanceItems = data; 
          //     resolve();
          //   }, 
          //   err => { 
          //     resolve();
          //     console.error (err); 
          //   }
          // );
        } catch (error) {
          resolve();
          console.error(error);          
        }
        //resolve();
      }

    });
  }

  private setEnvVariables(): boolean {
    var hostname : string = window && window.location && window.location.hostname;
    this._deploymentSettings = new DeployedEnvironment();

    //if (/^.*localhost.*/.test(hostname))
    if ((hostname == 'localhost') && (this.usingDocker == true)) {


      this._env = Environment.Dev;
      this._deploymentSettings = new DeployedEnvironment();

      this._deploymentSettings.production = false;
      this._deploymentSettings.apiUrl = 'http://localhost:8080/api/';
      this._deploymentSettings.apiAuthUrl = 'http://localhost:8080/user';
      this._deploymentSettings.apiStatsUrl = 'http://localhost:8080/stats/';
      this._deploymentSettings.apiWebUiUrl = 'http://localhost:7070/webui/';
      this._deploymentSettings.helpdDeskSignalRUrl = 'http://localhost:7070/orgchathub';
      this._deploymentSettings.apiExtendedUrl = 'http://localhost:7070/webui/apiext/';
      this._deploymentSettings.artifactServiceEndPoint = "ArtifactExtendedV2";
      this._deploymentSettings.publicContentEndPoint = "PublicContent";
      this._deploymentSettings.publicContentAccessKey = "a719d945-e5e7-4e70-94e4-7885e9e2cbb5";
      this._deploymentSettings.apiBiUrl = 'https://localhost:5021/bi/sql';
      this._deploymentSettings.portalApiUrl = 'https://localhost:5031/portal';

      return true;

    }
    else if (hostname == 'localhost') {
    

      this._env = Environment.Dev;
      this._deploymentSettings = new DeployedEnvironment();

      this._deploymentSettings.production = false;


      if (this.areLocalStoredSettingsForServerPresent === true)
      {
        this._deploymentSettings.apiUrl = this.localStoredApiUrl;
        this._deploymentSettings.apiAuthUrl = this.localStoredApiAuthUrl;
        this._deploymentSettings.apiWebUiUrl = this.localStoredApiWebUiUrl();
        this._deploymentSettings.apiExtendedUrl = this.localStoredApiExtendedUrl();
        this._deploymentSettings.portalApiUrl = this.localStoredApiPortalUrl();
      }
      else
      {
        this._deploymentSettings.apiUrl = `https://${hostname}:5001/api/`;
        this._deploymentSettings.apiAuthUrl = `https://${hostname}:5001/user`;
        this._deploymentSettings.apiWebUiUrl = `https://${hostname}:5011/webui/`;
        this._deploymentSettings.helpdDeskSignalRUrl = `https://${hostname}:5011/orgchathub`;
        this._deploymentSettings.apiExtendedUrl = `https://${hostname}:5011/webui/apiext/`;
        this._deploymentSettings.helpdDeskSignalRUrl = `https://${hostname}:5011/orgchathub`;
        this._deploymentSettings.portalApiUrl = `https://${hostname}:5031/portal/`;
    
      }
      this._deploymentSettings.apiStatsUrl = `https://${hostname}:5001/stats/`;
      this._deploymentSettings.artifactServiceEndPoint = `ArtifactExtendedV2`;
      this._deploymentSettings.publicContentEndPoint = `PublicContent`;
      this._deploymentSettings.publicContentAccessKey = `a719d945-e5e7-4e70-94e4-7885e9e2cbb5`;
      this._deploymentSettings.apiBiUrl = `https://${hostname}:5021/bi/sql`;


      // this._deploymentSettings.production = false;
      // this._deploymentSettings.apiUrl = `http://${hostname}:5000/api/`;
      // this._deploymentSettings.apiAuthUrl = `http://${hostname}:5000/user`;
      // this._deploymentSettings.apiStatsUrl = `http://${hostname}:5000/stats/`;
      // this._deploymentSettings.apiWebUiUrl = `https://${hostname}:5011/webui/`;
      // this._deploymentSettings.apiExtendedUrl = `https://${hostname}:5011/webui/apiext/`;
      // this._deploymentSettings.artifactServiceEndPoint = `ArtifactExtendedV2`;
      // this._deploymentSettings.publicContentEndPoint = `PublicContent`;
      // this._deploymentSettings.publicContentAccessKey = `a719d945-e5e7-4e70-94e4-7885e9e2cbb5`;
      // this._deploymentSettings.apiBiUrl = `https://${hostname}:5021/bi/sql`;

      return true;

    }
    else if (hostname === 'sys.alchemed.com' || hostname.endsWith('alchemed.b-cdn.net') || hostname.endsWith('cdn.alchemed.com')) {

      this._env = Environment.Prod;

      hostname= "sys.alchemed.com"
      this._deploymentSettings.production = true;
      this._deploymentSettings.apiUrl = `https://${hostname}/api/`;
      this._deploymentSettings.apiAuthUrl = `https://${hostname}/user`;
      this._deploymentSettings.apiStatsUrl = `https://${hostname}/stats/`;
      this._deploymentSettings.apiWebUiUrl = `https://${hostname}/webui/`;
      this._deploymentSettings.helpdDeskSignalRUrl = `https://${hostname}/orgchathub`;
      this._deploymentSettings.apiExtendedUrl = `https://${hostname}/webui/apiext/`;
      this._deploymentSettings.artifactServiceEndPoint = `ArtifactExtendedV2`;
      this._deploymentSettings.publicContentEndPoint = `PublicContent`;
      this._deploymentSettings.publicContentAccessKey = `a719d945-e5e7-4e70-94e4-7885e9e2cbb5`;
      this._apiUrl = '/api';
      this._deploymentSettings.apiBiUrl = `https://${hostname}/bi/sql`;
      this._deploymentSettings.portalApiUrl = `https://${hostname}/portal/`;
      return true;
    }
    else if ((hostname === 'sy2.alchemed.com') || hostname.endsWith('alchemed2.b-cdn.net') || hostname.endsWith('cd2.alchemed.com'))  {
      hostname= "sy2.alchemed.com"
      this._env = Environment.Prod;

      this._deploymentSettings.production = true;
      this._deploymentSettings.apiUrl = `https://${hostname}/api/`;
      this._deploymentSettings.apiAuthUrl = `https://${hostname}/user`;
      this._deploymentSettings.apiStatsUrl = `https://${hostname}/stats/`;
      this._deploymentSettings.apiWebUiUrl = `https://${hostname}/webui/`;
      this._deploymentSettings.helpdDeskSignalRUrl = `https://${hostname}/orgchathub`;
      this._deploymentSettings.apiExtendedUrl = `https://${hostname}/webui/apiext/`;
      this._deploymentSettings.artifactServiceEndPoint = `ArtifactExtendedV2`;
      this._deploymentSettings.publicContentEndPoint = `PublicContent`;
      this._deploymentSettings.publicContentAccessKey = `a719d945-e5e7-4e70-94e4-7885e9e2cbb5`;
      this._apiUrl = '/api';
      this._deploymentSettings.apiBiUrl = `https://${hostname}/bi/sql`;
      this._deploymentSettings.portalApiUrl = `https://${hostname}/portal/`;
      return true;
    }

    else if (hostname == 'alchemedstaging.ngrok.io') {
      this._env = Environment.Staging;
      this._deploymentSettings.production = true;
      this._deploymentSettings.apiUrl = 'https://alchemedstaging.ngrok.io/api/';
      this._deploymentSettings.apiAuthUrl = 'https://alchemedstaging.ngrok.io/user';
      this._deploymentSettings.apiStatsUrl = 'https://alchemedstaging.ngrok.io/stats/';
      this._deploymentSettings.apiWebUiUrl = 'https://alchemedstaging.ngrok.io/webui/';
      this._deploymentSettings.helpdDeskSignalRUrl = 'https://alchemedstaging.ngrok.io/orgchathub';
      this._deploymentSettings.apiExtendedUrl = 'https://alchemedstaging.ngrok.io/webui/apiext/';
      this._deploymentSettings.artifactServiceEndPoint = "ArtifactExtendedV2";
      this._deploymentSettings.publicContentEndPoint = "PublicContent";
      this._deploymentSettings.publicContentAccessKey = "a719d945-e5e7-4e70-94e4-7885e9e2cbb5";
      this._apiUrl = '/api';
      this._deploymentSettings.apiBiUrl = 'https://alchemedstaging.ngrok.io/bi/sql';
      this._deploymentSettings.portalApiUrl = 'https://alchemedstaging.ngrok.io/webui/apiext/';
      return true;
    }

    else if (hostname == 'www.alchemint.com') {


      this._env = Environment.Test;
      this._deploymentSettings.production = true;
      this._deploymentSettings.apiUrl = 'https://sys.alchemed.com/api/';
      this._deploymentSettings.apiAuthUrl = 'https://sys.alchemed.com/user';
      this._deploymentSettings.apiStatsUrl = 'https://sys.alchemed.com/stats/';
      this._deploymentSettings.apiWebUiUrl = 'https://sys.alchemed.com/webui/';
      this._deploymentSettings.helpdDeskSignalRUrl = 'https://sys.alchemed.com/orgchathub';
      this._deploymentSettings.apiExtendedUrl = 'https://sys.alchemed.com/webui/apiext/';
      this._deploymentSettings.artifactServiceEndPoint = "ArtifactExtendedV2";
      this._deploymentSettings.publicContentEndPoint = "PublicContent";
      this._deploymentSettings.publicContentAccessKey = "a719d945-e5e7-4e70-94e4-7885e9e2cbb5";
      this._apiUrl = '/api';
      return true;
    } else {

      this._env = Environment.Dev;
      this._deploymentSettings = new DeployedEnvironment();

      this._deploymentSettings.production = false;
      this._deploymentSettings.apiUrl = 'http://localhost:8080/api/';
      this._deploymentSettings.apiAuthUrl = 'http://localhost:8080/user';
      this._deploymentSettings.apiStatsUrl = 'http://localhost:8080/stats/';
      this._deploymentSettings.apiWebUiUrl = 'http://localhost:7070/webui/';
      this._deploymentSettings.helpdDeskSignalRUrl = 'http://localhost:7070/orgchathub';
      this._deploymentSettings.apiExtendedUrl = 'http://localhost:7070/webui/apiext/';
      this._deploymentSettings.artifactServiceEndPoint = "ArtifactExtendedV2";
      this._deploymentSettings.publicContentEndPoint = "PublicContent";
      this._deploymentSettings.publicContentAccessKey = "a719d945-e5e7-4e70-94e4-7885e9e2cbb5";
      this._deploymentSettings.apiBiUrl = 'https://localhost:5021/bi/sql';
      this._deploymentSettings.portalApiUrl = 'https://localhost:5031/portal';
      return true;

      // alert(`Unsupported environment : ` + hostname);
      // return false;
    }
  }


  public get runningFromBetaFolder(): boolean {
    return this.isRunningInBeta;
  }

  public get isRunningInDev(): boolean {
    return (this.env == Environment.Dev);
  }

  private USE_DOCKER_SETTING_KEY: string = 'use_docker_in_dev';

  public get usingDocker(): boolean {
    var data = localStorage.getItem(this.USE_DOCKER_SETTING_KEY);
    if (data) {
      return (data.toLowerCase() === 'true');
    }
    else {
      return true;
    }
  }

  public set usingDocker(value: boolean) {
    localStorage.setItem(this.USE_DOCKER_SETTING_KEY, String(value)?.toLowerCase());
  }
  public get environmnetName(): string {
    return Environment[this.env];
  }


 
}