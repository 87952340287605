import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EmailResultDTO, FullPatientDetails, UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { eWebAppOrgSettings, SettingsService } from '@app/_services/settings-service.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { Contact, CustomShortcutKeys } from '@app/_alchemint/alchemint_dm';
import { PatientService } from '@app/_services/patient.service';
import { ContactManagerService } from '@app/contact-manager/contact-manager.service';
import { EnvService, Environment } from '@app/_services/environment.service';
import { EmailService } from '@app/email.service';
import { EmailMsgService } from '@app/_services/email-service.service';
import { file } from 'jszip';
import { ShortcutKeyExecuter } from '@app/artifact-editor/ShortCutKeyHelper';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.scss'],
  providers: [EmailMsgService]
})
export class EmailEditorComponent implements OnInit, AfterViewInit {

  private patientId: string;
  public patient: FullPatientDetails;
  //public htmData : string;
  public messageBody: string = '';
  public messageSubject: string = '';
  public messageAttachmentArtifactId: string;
  public attachmentFileData: File;

  public allowEmailAddressEntry: boolean = false;
  @Output() errorEvent = new EventEmitter<any>();


  public sendingEmail: boolean = false;
  public selectedRecipientType: string = '';

  // @ViewChild('messageBodyTextArea') messageBodyTextArea : ElementRef;

  @ViewChild('messageBodyTextArea') messageBodyTextArea: ElementRef<HTMLDivElement>;


  contactsSelections: ContactsSelections[] = [];

  @ViewChild('subjectInput') subjectInputRef: ElementRef;
  // selectedContacts: any[] = []; // Make sure this is an array

  onContactsSelected(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    //this.selectedContacts = Array.from(selectElement.selectedOptions).map(option => option.value);
    // Now `this.selectedContacts` will have all the selected contacts.
  }
  // onContactSelected ($event : any)
  // {
  //   this.selectedContact = $event.option.value;
  // }

  // selectedContact: Contact = null;


  public attachmentArtifactId: string;
  public toEmailAddresses: string
  public ccEmailAddresses: string;
  public contacts: Contact[] = [];

  public hideCCInput: boolean = false;
  public hideAttachButton: boolean = false;
  constructor(private apiInterfaceService: ApiInterfaceService, private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public userSeletInfo: UserSeletInfo,
    public dialogRef: MatDialogRef<EmailEditorComponent>,
    public changeDetector: ChangeDetectorRef,
    public alchemintSharedService: AlchemintSharedService,
    private webApiInterfaceService: WebApiInterfaceService,
    private patientService: PatientService,
    contactManagerService: ContactManagerService,
    private env: EnvService,
    private emailMsgService: EmailMsgService,
    private datepipe: DatePipe,
    authenticationService: AuthenticationService


  ) {

    this.userName = authenticationService.userNameFromToken;


    var fetch$ = contactManagerService.contacts();

    var sub = fetch$.subscribe(
      (contacts) => {
        this.contacts = contacts;
        this.contactsSelections = this.contacts.map(contact => new ContactsSelections(contact, false));

        this.selectedRecipientType = (contacts?.length > 0) ? 'contact' : 'anyone';

        sub.unsubscribe();
      },
      (error) => {
        this.alchemintSharedService.oppenErrDialogForObjectErr(error);
        sub.unsubscribe();
      }
    );


    //this.route = this.activatedRoute.snapshot.root;
    this.activatedRoute.params.subscribe(params => {
      if (params['patientid']) {
        this.getAndSetPatient(params['patientid'], false);
      }
    });


    this.hideCCInput = userSeletInfo.hideCCInput
    this.hideAttachButton = userSeletInfo.hideAttachButton;

    if (userSeletInfo?.allowEmailAddressEntry === true) {
      this.allowEmailAddressEntry = userSeletInfo.allowEmailAddressEntry;
      this.toEmailAddresses = null;
    }
    else {
      this.allowEmailAddressEntry = false;
      this.toEmailAddresses = null;
    }

    if (userSeletInfo.fullPatientDetails) {
      this.setPatient(userSeletInfo.fullPatientDetails, this.allowEmailAddressEntry);
    }
    else if (userSeletInfo?.patient?.id) {
      this.getAndSetPatient(userSeletInfo.patient.id, this.allowEmailAddressEntry);
    }
    else if (userSeletInfo?.patientId) {
      this.getAndSetPatient(userSeletInfo?.patientId, this.allowEmailAddressEntry);
    }

    if (userSeletInfo?.dynamicProperties["emailSubject"]) {
      this.messageSubject = userSeletInfo?.dynamicProperties["emailSubject"];
    }

    if (userSeletInfo?.dynamicProperties["emailBody"]) {
      this.messageBody = userSeletInfo?.dynamicProperties["emailBody"];
    }

    if (userSeletInfo?.dynamicProperties["attachmentArtifactId"]) {
      this.attachmentArtifactId = userSeletInfo?.dynamicProperties["attachmentArtifactId"];
    }

    if (userSeletInfo?.dynamicProperties["attachmentFileData"]) {
      this.attachmentFileData = userSeletInfo?.dynamicProperties["attachmentFileData"];
    }




    if (userSeletInfo?.dynamicProperties["emailAddress"]) {
      this.toEmailAddresses = userSeletInfo?.dynamicProperties["emailAddress"];
    }
  }

  filteredContactsSelections()
  {
    if (this.emailFilter)
    {
      return this.contactsSelections?.filter(cs => cs.contact.firstName.toLowerCase().includes(this.emailFilter.toLowerCase()));
    }
    else
    {
      return this.contactsSelections;
    }
    
  }
  sendDisabled() {


    var selContacts = this.getSelectedContacts();
    var hasSelectedContacts: boolean = false;
    if (selContacts?.length > 0) {
      hasSelectedContacts = true;
    }

    if (!this.messageSubject) {
      return true;
    }

    if ((!this.toEmailAddresses) && (hasSelectedContacts === false)) {
      return true;
    }
    else if (this.toEmailAddressesInvalid) {
      return true;
    }
    else {
      return false;
    }


  }


  getSelectedContacts(): Contact[] {
    // Filter out only selections where 'selected' is true, then map to get the Contact objects.
    return this.contactsSelections.filter(cs => cs.selected).map(cs => cs.contact);
  }



  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.htmData = 'TEST';
    // this.loadWysiwygEditorComponent(this.htmData);


    this.shortCutKeys = ShortcutKeyExecuter.skCustomCutKeys;

    this.messageBodyTextArea.nativeElement.innerHTML = this.messageBody;



    if (this.messageSubject?.length === 0) {
      this.subjectInputRef.nativeElement.focus();
      setTimeout(() => {
        this.subjectInputRef.nativeElement.focus();
      }, 100); // Adjust the timeout as needed
    }
    else {
      this.SetFocusInMessage();
      setTimeout(() => {
        this.SetFocusInMessage();
      }, 100);

    }


  }

  public setPatient(fullPatientDetails: FullPatientDetails, allowEmailAddressEntry: boolean): void {
    this.patient = fullPatientDetails;
    this.patientId = fullPatientDetails.patient?.id;
    if (allowEmailAddressEntry === false) {
      this.toEmailAddresses = fullPatientDetails?.contactDetails?.emailAddress;
    }
  }

  public getAndSetPatient(patientId: string, allowEmailAddressEntry: boolean): void {
    this.patientId = patientId;
    this.apiInterfaceService.getPatientWithFullDetailsWebGuiApiCall(patientId).subscribe(
      patient => {
        this.setPatient(patient, allowEmailAddressEntry);
      },
      error => { this.errorEvent?.emit(error); }
    );
  }


  private getModifiedBody(): string {
    this.changeDetector.detectChanges();
    return this.messageBodyTextArea.nativeElement.innerHTML;
  }

  public sendEmail() {
    if (this.allowEmailAddressEntry) {
      this._sendEmailAnyone();
    }
    else {
      this._sendEmail();
    }

  }
  private _sendEmail() {

    // var messageBody = this.wysiwygEditorComponent.instance.getEditorBodyHtm();


    var body = this.getModifiedBody();
    if (!body) {
      this.alchemintSharedService.openSnackBar('Please enter content for the email');
    }
    else {
      this.sendingEmail = true;

      var storeEmail: boolean = this.settingsService.getSettingValue(eWebAppOrgSettings.StoreOutgoingEmails) || false;

      var allAttachments: File[] = [];
      if (this.selectedFiles?.length > 0) {
        allAttachments.push(...this.selectedFiles);
      }
      if (this.attachmentFileData) {
        allAttachments.push(this.attachmentFileData);
      }
      this.apiInterfaceService.sendPatientEmail(this.patientId, this.messageSubject, storeEmail, body, this.attachmentArtifactId, allAttachments, this.ccEmailAddresses).subscribe(
        (emailResult: EmailResultDTO) => {
          this.sendingEmail = false;

          if (emailResult.success === false) {
            this.alchemintSharedService.oppenErrDialogForObjectErr(emailResult.failureMessage);
          }

          this.dialogRef.close(emailResult.success);

        },
        error => {
          this.sendingEmail = false;
          this.alchemintSharedService.openErrorSnackBar(error.error || error.message);

        }
      );

    }
  }


  public _sendEmailAnyone() {


    var body = this.getModifiedBody();
    if (!body) {
      this.alchemintSharedService.openOkMessageBoxSync('No Content', 'Please enter content for the email');
    }
    else {

      var storeEmail: boolean = this.settingsService.getSettingValue(eWebAppOrgSettings.StoreOutgoingEmails);
      if (storeEmail === null) {
        storeEmail = false;
      }
      var sendTo: string = null;
      var selectedContacts = this.getSelectedContacts();
      if ((this.selectedRecipientType == 'contact') && (selectedContacts?.length > 0)) {
        sendTo = selectedContacts.map(x => x.email).join(';');
      }
      else if (this.toEmailAddresses) {
        sendTo = this.toEmailAddresses;
      }

      if (this.patientService.isValidEmailList(sendTo) === false) {
        this.alchemintSharedService.openOkMessageBoxSync('Invalid Email Address', 'The email address entered is not valid. Please correct the email address and try again.');
        return;
      }
      else {
        this.sendingEmail = true;
      }



      var allAttachments: File[] = [];
      if (this.selectedFiles?.length > 0) {
        allAttachments.push(...this.selectedFiles);
      }
      if (this.attachmentFileData) {
        allAttachments.push(this.attachmentFileData);
      }

      this.webApiInterfaceService.sendEmailAnyone(sendTo, this.patientId, this.messageSubject, storeEmail, body, this.attachmentArtifactId, allAttachments, this.ccEmailAddresses).subscribe(
        (emailResult) => {
          this.sendingEmail = false;

          if (emailResult.success === false) {
            this.alchemintSharedService.oppenErrDialogForObjectErr(emailResult.failureMessage);
          }

          this.dialogRef.close(emailResult.success);

        },
        error => {
          this.sendingEmail = false;
          this.alchemintSharedService.openErrorSnackBar(error.error || error.message);

        }
      );

    }
  }




  public SetFocusInMessage(): void {
    var input = this.messageBodyTextArea.nativeElement;

    // ✅ Move focus to Beginning of input field
    // input.setSelectionRange(0, 0);
    input.focus();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  public get toEmailAddressesInvalid(): boolean {
    if (this.toEmailAddresses) {
      return (this.patientService.isValidEmailList(this.toEmailAddresses) === false);
    }
    else {
      return false;
    }
  }

  selectedFiles: File[] = []; // Store selected files

  // Called when files are selected
  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files.item(i)); // Add selected files to array
      }
    }
  }

  // You might want to add a method to clear the list or remove specific files
  removeAttachment(index: number): void {
    this.selectedFiles.splice(index, 1); // Remove file from the list
  }


  public get showVerifyEmail(): boolean {
    return (this.env.env === Environment.Dev);
  }

  public verifyEmail() {
    this.webApiInterfaceService.verifyEmailExists(this.toEmailAddresses).subscribe(
      (result) => {
        if (result === true) {
          this.alchemintSharedService.openOkMessageBoxSync('Email Address Verified', 'The email address is valid and exists.');
        }
        else {
          this.alchemintSharedService.openOkMessageBoxSync('Email Address Not Verified', 'The email address is not valid or does not exist.');
        }

      },
      (error) => {
        this.alchemintSharedService.openErrorSnackBar(error.error || error.message);

      }
    );
  }

  async generateMsgFile() {
    //Please convert    this.attachmentFileData from a File to a Blob


    var body = this.getModifiedBody();
    var blob: Blob;
    var blobs: Blob[] = [];
    var files;
    // if (this.attachmentFileData)
    // {
    //   blob = this.fileToBlob(this.attachmentFileData);
    //   //blobs.push(blob);
    //   files = [ { filename: this.attachmentFileData.name, content: blob, mimeType: 'image/png' } ];
    // }
    // else
    // {
    //   files = [];
    // }



    var content = await this.emailMsgService.generateEmlFileForBinaryAttachments(
      "darryl@alchemed.com",
      this.toEmailAddresses,
      [],
      this.messageSubject,
      body,
      [
        // { filename: 'test.txt', content: 'This is a test attachment', mimeType: 'text/plain' },
        { filename: this.attachmentFileData.name, content: this.attachmentFileData, mimeType: this.attachmentFileData.type }
      ])



    // this.emailMsgService.saveEmlFile(content, this.messageSubject + '.eml');

  }

  fileToBlob(file: File): Blob {
    return new Blob([file], { type: file.type });
  }

  public textChanged: boolean = false;
  public userName: string;
  public shortCutKeys: CustomShortcutKeys[];

  public emailFilter: string;


  editorKeyPress(event: KeyboardEvent) {
    // Return early if Ctrl key is not pressed or if only Ctrl key is pressed
    if (!event.ctrlKey || event.key === 'Control') {
      return;
    }




    // Execute the shortcut key handler
    ShortcutKeyExecuter._editorKeyPress(
      event,
      this.userName,
      this.datepipe,
      this.apiInterfaceService,
      this.messageBodyTextArea.nativeElement,
      this.textChanged,
      this.patientId,
      this.shortCutKeys,
      null
    ).subscribe(
      result => {
        this.textChanged = result.textChanged;
      },
      error => {
        this.errorEvent?.emit(error);
      }
    );
  }
}


class ContactsSelections {
  constructor(public contact: Contact, public selected: boolean) { }
}
