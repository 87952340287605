import { EventEmitter, Injectable , Output} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { UserAlchemint } from '@app/_models/user';
import  *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { DeployedEnvironment, EnvService, Environment } from './environment.service';
import { UIConfig } from '@app/_alchemint/alchemint_composite_requests';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  setBackOfficeApiKey(value: string) {

    localStorage.setItem("backofficeApiKey", value);
  }


    public userSubject: BehaviorSubject<UserAlchemint>;
    public user: Observable<UserAlchemint>;
    private apiKeySubject: BehaviorSubject<string>;
    public apiKey: Observable<string>;
    private alchemintApplicationMetaData : Alchemint.AlchemintApplicationMetaData;
    private useCookies : boolean = false;
    private apiKeyStoreKeyName : string = 'alcApiKey';
    private userStoreKeyName : string = 'alcUser';
    public keepUserLoggedIn : boolean = false;
    private environment : DeployedEnvironment;
    public userLoggedOut : boolean = false;

    private storeUserTokenKey : string = 'alcAuthTk';
    private storeRefreshTokenKey : string = 'alcRefreshTk';
    private storeUserIdKey : string = 'alcAuthLoginId';
    public isMasterUser : boolean = false;
    public reloggingInWithRefreshToken : boolean = false;
    public loggedInUserId : Observable<string> = new Observable<string> ( observer => { observer.next (this.userIdFromToken); } );
    public loginComplete = new Subject();

    public get helpDeskApiKey () : string 
    {
      return localStorage.getItem('helpDeskApiKey');
    }

    public set helpDeskApiKey (val : string)
    {
      if (val)
      {
        localStorage.setItem('helpDeskApiKey', val);
      }
      
    }

    public physioPartnerApiKeys: string [] = ['e0b6d066-7c18']; //, '30e8c93a-6a83'
    public loggedInUserName: string;

    public useNewAuthenticationMethod : boolean = true;

 
    public get backofficeApiKey (): string 
    {
      return localStorage.getItem("backofficeApiKey");
    }

    @Output() logInSuccess = new EventEmitter();
    @Output() logInFail = new EventEmitter();

    @Output() requestAppLogout = new EventEmitter();
    
    
    public get IsRetinaAssistLicense(): boolean
    {
      
      if (this.environmentService.env === Environment.Dev)
      {
        if (this.getStoredApiKey()?.startsWith(`---30e8c93a-6a83`))        
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else if (this.getStoredApiKey()?.startsWith(`69838eee-baa2-47a5`))
        {
          return true;
        }
        else
        {
          return false;
        }
    }

    public get UIConfig (): UIConfig
    {
      var ui = new UIConfig();

      if (this.IsRetinaAssistLicense)
      {
        ui.clinicalNotes = false;
        ui.pathology = false;
        ui.radiology = false;
        ui.scripts = false;
        ui.sickNotes = false;
        ui.theatreSlate = false;
        ui.inPatients = false;
        ui.tasks = false;
        ui.search = false;
        ui.more = false;
        ui.modules = true;
        ui.configuration = false;
      }
      else
      {
        ui.clinicalNotes = true;
        ui.pathology = true;
        ui.radiology = true;
        ui.scripts = true;
        ui.sickNotes = true;
        ui.theatreSlate = true;
        ui.inPatients = true;
        ui.tasks = true;
        ui.search = true;
        ui.more = true;
        ui.modules = true;
        ui.configuration = true;

      }
      return ui;

    }

    public isMasterOrg(): boolean {
      return (this.environmentService.env === Environment.Dev);
    }

    public storeLogidInUser(login: UserAlchemint)
    {
      // Clone the login using spread operator into a new variable called loginCloned
      var loginCloned = { ...login };
      loginCloned.passwordHash = null;
      localStorage.setItem("currentLoggedInUser", JSON.stringify(loginCloned));
    }

    public getStoreLogidInUser(): UserAlchemint
    {
      var data = localStorage.getItem("currentLoggedInUser");

      if (data)
      {
        var login = JSON.parse(data);
        return login;
      }
      else
      {
        return null;
      }
      
    }

    deleteStoredLoginUser (): void{
      localStorage.removeItem("currentLoggedInUser");
    }


    public broadcastStoredLogidInUser()
    {

      var login = this.getStoreLogidInUser();
      //var data = localStorage.getItem("currentLoggedInUser");

      if (login)
      {
        this.userSubject.next(login);
      }
      else
      {
        console.log("broadcastStoredLogidInUser - no user found");
      }
      
    }


    public get userIdFromToken () : string
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        return jwtToken.LOGIN_ID;
      }
      else
      {
        return null;
      }
    }

    public get clientIdFromToken () : string
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        return jwtToken.CLIENT_ID;
      }
      else
      {
        return null;
      }
    }
    public get userNameFromToken () : string
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        return jwtToken.USERNAME;
      }
      else
      {
        return null;
      }
    }

    public get masterRightsFromToken () : boolean
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        return jwtToken.MASTER_RIGHTS;
      }
      else
      {
        return null;
      }
    }

    public get versionTsAndCsFromToken () : number
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        return +jwtToken.VER_TS_AND_CS;
      }
      else
      {
        return -1;
      }
    }

    public get isiHealthIntegratedModeFromToken () : boolean
    {
      if (this.integratedOrgIdFromToken)
      {
        return true;
      }
      else
      {
        return false;
      }
    }

    public get integratedOrgIdFromToken () : string
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        return jwtToken.INTEGRATED_ORG_ID;
      }
      else
      {
        return null;
      }
    }
    



    //new Claim("", user.UserName),

    public get isDemoCompanyFromToken () : boolean
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      if (token)
      {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));

        if (jwtToken.IS_DEMO_PRAC)
        {
          return (jwtToken.IS_DEMO_PRAC.toLowerCase() === "true");
        }
        else
        {
          return false;
        }

      }
      else
      {
        return false;
      }
    }

    public get practiceNumberFromToken () : string
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      const jwtToken = JSON.parse(atob(token.split('.')[1]));
      return jwtToken.INTEGRATED_PRAC_NUM;
    }


    public get practitionerIdFromToken () : string
    {

      var token : string;
      if (this.integratedLoggedIn)
      {
        token = this.integratedBearerToken;
      }
      else
      {
        token = this.storedBearerToken;
      }

      const jwtToken = JSON.parse(atob(token.split('.')[1]));
      return jwtToken.INTEGRATED_PRACTITIONER_ID;
    }

    public get userCanSubmitBillingForms () : boolean
    {
      return ((this.practiceNumberFromToken?.trim().length > 0) || (this.practitionerIdFromToken?.trim().length > 0))
    }




    constructor(
        private router: Router,
        private http: HttpClient,
        private environmentService : EnvService
    ) {
        this.userSubject = new BehaviorSubject<UserAlchemint>(null);
        this.user = this.userSubject.asObservable();
        this.apiKeySubject = new BehaviorSubject<string>(null);
        this.apiKey = this.apiKeySubject.asObservable();
        this.alchemintApplicationMetaData = new Alchemint.AlchemintApplicationMetaData()
        this.environment = environmentService.deploymentSettings;
    }

    public attemptedAutoLogonRecently : boolean = false;
    public recentAttemptedAutoLogonCount : number = 0;






    public get userValue(): UserAlchemint {
      if (this.useNewAuthenticationMethod)  
      {
        //this.broadcastStoredLogidInUser();
        return this.getStoreLogidInUser();
      }
      else
      {
        return this.userSubject.value;
      }
      
    }

    public get apiKeyValue(): string {
        return this.apiKeySubject.value;
    }

    public get isPhysioDevelopmentPartner (): boolean
    {
      return this.physioPartnerApiKeys.some(apiKey => this.apiKeyValue?.startsWith(apiKey));
    }


    private _memoryHeldUserPassword : string = null;

    public getMemoryHeldUserPassword () : string
    {
      return this._memoryHeldUserPassword;
    }

    public set storedBearerToken (value :string)
    {
      if (!(value))
      {
        localStorage.removeItem(this.storeUserTokenKey);
      }
      else
      {
        localStorage.setItem(this.storeUserTokenKey, value);
      }

    }

    public get storedBearerToken () : string
    {
      var storedBearerToken = this.getItem(this.storeUserTokenKey);
      if (storedBearerToken == 'null')
      {
        localStorage.removeItem(this.storeUserTokenKey);
        return null;
      }
      else
      {
        return storedBearerToken;
      }
    }

    // public set storedLoginId (value :string)
    // {
    //   if (!(value))
    //   {
    //     localStorage.removeItem(this.storeUserIdKey);
    //   }
    //   else
    //   {
    //     localStorage.setItem(this.storeUserIdKey, value);
    //   }
    // }

    // public get storedLoginId () : string
    // {
    //   var storedLoginId = this.getItem(this.storeUserIdKey);
    //   if (storedLoginId == 'null')
    //   {
    //     localStorage.removeItem(this.storeUserIdKey);
    //     return null;
    //   }
    //   else
    //   {
    //     return storedLoginId;
    //   }
    // }

    public set storedRefreshToken (value :string)
    {
      if (!(value))
      {
        localStorage.removeItem(this.storeRefreshTokenKey);
      }
      else
      {
        localStorage.setItem(this.storeRefreshTokenKey, value);
      }

    }
    public get storedRefreshToken () : string
    {
      var storedRfToken = this.getItem(this.storeRefreshTokenKey);
      if (storedRfToken == 'null')
      {
        localStorage.removeItem(this.storeRefreshTokenKey);
        return null;
      }
      else
      {
        return storedRfToken;
      }
    }

    public set integratedBearerToken (value :string)
    {
      var tokenStorerElement = < HTMLInputElement> document.getElementById("integratedToken");
      if (tokenStorerElement)
      {
          tokenStorerElement.value = value;
      }
    }

    public get integratedBearerToken () : string
    {
        var tokenStorerElement = < HTMLInputElement> document.getElementById("integratedToken");
        if (tokenStorerElement)
        {
            var tokenVal : string = tokenStorerElement.value;
            if (tokenVal == null)
            {
              return null;
            }
            else if (tokenVal.length <= 0)
            {
              return null;
            }
            else
            {
              this.userLoggedOut = true;
              return tokenStorerElement.value;
            }

        }
        else
        {
          return null;
        }
    }

    public get integratedLoggedIn () : boolean
    {
       return (this.integratedBearerToken != null);
    }

    public set integratedApiKey (value :string)
    {
      var tokenStorerElement = < HTMLInputElement> document.getElementById("integratedApiKey");
      if (tokenStorerElement)
      {
          tokenStorerElement.value = value;
      }
    }

    public get integratedApiKey () : string
    {
        var tokenStorerElement = <HTMLInputElement> document.getElementById("integratedApiKey");
        if (tokenStorerElement)
        {
            return tokenStorerElement.value;
        }
        else
        {
          return null;
        }
    }

    public set integratedUserName (value :string)
    {
      var tokenStorerElement = <HTMLInputElement> document.getElementById("integratedUserName");
      if (tokenStorerElement)
      {
          tokenStorerElement.value = value;
      }
    }

    public get integratedUserName () : string
    {
        var integratedUserNameElement = <HTMLInputElement> document.getElementById("integratedUserName");
        if (integratedUserNameElement)
        {
            return integratedUserNameElement.value;
        }
        else
        {
          return null;
        }
    }

    public set integratedUserId (value :string)
    {
      var tokenStorerElement = <HTMLInputElement> document.getElementById("integratedUserId");
      if (tokenStorerElement)
      {
          tokenStorerElement.value = value;
      }
    }

    public get integratedUserId () : string
    {
        var integratedUserIdElement = <HTMLInputElement> document.getElementById("integratedUserId");
        if (integratedUserIdElement)
        {
            return integratedUserIdElement.value;
        }
        else
        {
          return null;
        }
    }


    public set integratedLoginIsReadOnly (value :boolean)
    {
      var tokenStorerElement = <HTMLInputElement> document.getElementById("integratedLoginIsReadOnly");
      if (tokenStorerElement)
      {
        if (value)
        {
          tokenStorerElement.value = "true";
        }
        else
        {
          tokenStorerElement.value = "false";
        }
      }
    }


    public get integratedLoginIsReadOnly () : boolean
    {
        var integratedUserIdElement = <HTMLInputElement> document.getElementById("integratedLoginIsReadOnly");
        if (integratedUserIdElement)
        {
            if (integratedUserIdElement.value.toLowerCase() == "true")
            {
              return true;
            }
            else
            {
              return false;
            }
        }
        else
        {
          return false;
        }
    }
    private _showOTPform = new Subject<UserAlchemint>();

    public onShowOTPform() : Observable<UserAlchemint> {
      return this._showOTPform.asObservable();
    }

    public showOTPform(user: any) : void {
      this._showOTPform.next(user)
    }

    // login(username: string, password: string, apiKey : string, keepUserLoggedIn : boolean, useApiKeyFile : boolean) {

    //     this.keepUserLoggedIn = keepUserLoggedIn;
    //     this.apiKeySubject.next(apiKey);
    //     var user1 = new UserAlchemint();
    //     user1.userName = username;
    //     user1.passwordHash = password;
    //     var qry = this.buildQueryParamatersFromEntity<UserAlchemint>(user1);
    //     var url = `${this.environment.apiAuthUrl}/webauthenticate/authenticateincluderefreshtk?${qry}`;

    //     return this.http.get<any>(url, { withCredentials: true });
    // }

  
    loginUsingPost(username: string, password: string, apiKey : string, keepUserLoggedIn : boolean, useApiKeyFile : boolean) {

      this.keepUserLoggedIn = keepUserLoggedIn;
      this.apiKeySubject.next(apiKey);
      //var user1 = new UserAlchemint();
      //user1.userName = username;
      //user1.passwordHash = password;
      //var qry = this.buildQueryParamatersFromEntity<UserAlchemint>(user1);
      var url = `${this.environment.apiAuthUrl}/webauthenticate/authenticateincluderefreshtkpost`;
      var lg: Alchemint.Login = new Alchemint.Login();
      lg.userName = username;
      lg.passwordHash = password;
      return this.http.post<any>(url, lg, { withCredentials: true });
  }

  private mapToUserAlchemintObject (login: Alchemint.Login): UserAlchemint
  {
    var us: UserAlchemint = new UserAlchemint();
    us.userName = login.userName;
    us.id = login.id;
    us.deleted = login.deleted;
    us.isAdminStaff = login.isAdminStaff;
    us.masterPriveledges = login.masterPriveledges;
    us.passwordHash = "";
    us.token = login.token;
    us.readOnlyLogon = login.readOnlyLogon;
    us.cellPhoneNumber= login.cellPhoneNumber;
    us.latestTemsAndConditionsVersionAgreedTo = login.latestTemsAndConditionsVersionAgreedTo;
    return us;
  }

  
  public handleLoginSuccess(user: any,keepUserLoggedIn: boolean, password: string, useApiKeyFile: boolean, apiKey: string) {
    var us: UserAlchemint = new UserAlchemint();
    
    us = this.mapToUserAlchemintObject(user);
    us.passwordHash = password;
    us.twoFactorAuthSettings = user.twoFactorAuthSettings;    
    
    //this.storedBearerToken = user.storedBearerToken;
    this.storedBearerToken = user.token;

    this.userLoggedOut = false;

    this.isMasterUser = this._isMasterUser(us.userName);
    if (this.keepUserLoggedIn == true) {
      this.storedRefreshToken = user.refreshToken.token;
      //this.StoreUser(us, keepUserLoggedIn);
      this.setStoredApiKey(user.refreshToken.apiKey);
      this._memoryHeldUserPassword = password;
    } else {
      this.storedRefreshToken = null;
    }

    if (useApiKeyFile) {
      this.pushApiKEyFileEntry(apiKey, apiKey);
    }

    if (this.useNewAuthenticationMethod)
    {
      this.storeLogidInUser(us);
      this.broadcastStoredLogidInUser();
    }
    else
    {
      this.userSubject.next(us);
    }


    
    this.loginComplete.next(1);
    this.startRefreshTokenTimer();

    this.countRefreshTokenLoginAttemps = 0;
    
    

    this.logInSuccess?.emit();

    return user;
  }

    PushUser(storedUser : UserAlchemint, storedApiKey: string)
    {
      
      if (this.useNewAuthenticationMethod)
      {
        this.storeLogidInUser(storedUser);
        this.broadcastStoredLogidInUser();
      }
      else
      {
        this.userSubject.next(storedUser);
      }
      
      
      this.apiKeySubject.next(storedApiKey);
    }

    logout(tryRevokeToken : boolean, clearStoredToken : boolean, clearStoredUser : boolean, clearRefreshToken : boolean, clearMemoryHeldUserPassword : boolean, clearStoredUserDetails: boolean, routeToLoginPage: boolean = true ) {

        this.reloggingInWithRefreshToken = false;
        if (this.integratedLoggedIn)
        {
          this.integratedApiKey = null;
          this.integratedBearerToken = null;
          this.integratedLoginIsReadOnly = false;
          this.integratedUserId = null;
          this.integratedUserName = null;
        }

        if (clearMemoryHeldUserPassword)
        {
          this._memoryHeldUserPassword = null;
        }

        if (tryRevokeToken == false)
        {
          if (clearStoredToken)
          {
            this.storedBearerToken = null;
          }
  
          if (clearRefreshToken)
          {
            this.storedRefreshToken = null;
          }

          if (clearStoredUserDetails)
          {
            this.deleteStoredLoginUser()
          }

          if (routeToLoginPage)
          {
            this.router.navigate(['/login']);
          }
          
          this.userLoggedOut = true;
          if (clearStoredUser)
          {
            this.DeleteStoredUser();
          }

          this.stopRefreshTokenTimer();
          this.userSubject.next(null);
          //this.router.navigate(['/login']);
        }
        else
        {
          this.http.post<any>(`${this.environment.apiAuthUrl}/revoke-token`, {}, { withCredentials: true }).pipe(
            map(x => {
                // this.deleteStoredApiKey();
                this.userLoggedOut = true;
                if (clearStoredUser)
                {
                  this.DeleteStoredUser();
                }
                this.stopRefreshTokenTimer();
                this.userSubject.next(null);
                
                if (routeToLoginPage)
                {
                  this.router.navigate(['/login']);
                }
                

                if (clearStoredToken)
                {
                  this.storedBearerToken = null;
                }
        
                if (clearRefreshToken)
                {
                  this.storedRefreshToken = null;
                }

                if (clearStoredUserDetails)
                {
                  this.deleteStoredLoginUser()
                }
      
      
            }),
            catchError(error => {
                // this.deleteStoredApiKey();
                this.userLoggedOut = true;
                if (clearStoredUser)
                {
                  this.DeleteStoredUser();
                }
                this.stopRefreshTokenTimer();
                this.userSubject.next(null);

                if (routeToLoginPage)
                {
                  this.router.navigate(['/login']);
                }
                

                if (clearStoredToken)
                {
                  this.storedBearerToken = null;
                }
        
                if (clearRefreshToken)
                {
                  this.storedRefreshToken = null;
                }
      
                if (clearStoredUserDetails)
                {
                  this.deleteStoredLoginUser()
                }
      
                return of([])},

                )
        ).subscribe();

        }

    }

    delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }
    refreshToken() {

        var apiKey : string  = this.getStoredApiKey();

        if (apiKey)
        {
            this.apiKeySubject.next(apiKey);
            var httpHead = {};

            this.apiKeySubject.next(apiKey);
            this.reloggingInWithRefreshToken = true;

            return this.http.post<any>(`${this.environment.apiAuthUrl}/webauthenticate/refresh-token-auth`, {}, { withCredentials: true })
                .pipe(map((user) => {
                    this.reloggingInWithRefreshToken = false;
                    this.userLoggedOut = false;
                    
                    if (this.useNewAuthenticationMethod)
                    {
                      this.storeLogidInUser(this.mapToUserAlchemintObject(user));
                      this.broadcastStoredLogidInUser();
                    }
                    else
                    {
                      this.userSubject.next(user);
                    }
                    
                    
                    
                    this.startRefreshTokenTimer();
                    this.logInSuccess?.emit();
                    return user;
                }, (error) => {
                    this.reloggingInWithRefreshToken = false;
                    this.logInFail?.emit();
                    alert(error);
                }));
        }
        else
        {
            return this.ok( {name : ""} );
        }

    }


    public countRefreshTokenLoginAttemps : number = 0;

    logonWithStoredRefreshToken() : Observable<boolean>
    {

      this.countRefreshTokenLoginAttemps ++;

      return new Observable<boolean> (
        (observer) => {




          var apiKey : string  = this.getStoredApiKey();

          if (apiKey && this.storedRefreshToken)
          {

            // if (this.countRefreshTokenLoginAttemps > 3)
            // {
            //   this.storedRefreshToken = null;
            //   observer.error("Login attempts with refresh token failed");
            // }

              this.apiKeySubject.next(apiKey);
              var httpHead = {};

              this.apiKeySubject.next(apiKey);

              //var refreshToken = this.storedRefreshToken;
              var refreshToken = encodeURIComponent(this.storedRefreshToken);

              this.reloggingInWithRefreshToken = true;
              var promis = this.http.post<any>(`${this.environment.apiAuthUrl}/webauthenticate/refresh-token-auth-supplied/` + refreshToken, {}, { withCredentials: true })
                  .pipe(map((user) => {
                      this.reloggingInWithRefreshToken = false;
                      this.userLoggedOut = false;
                      
                      
                      
                      if (this.useNewAuthenticationMethod)
                      {
                        this.storeLogidInUser(this.mapToUserAlchemintObject(user));
                        this.broadcastStoredLogidInUser();
                      }
                      else
                      {
                        this.userSubject.next(user);
                      }

                      this.storedBearerToken = user.token;
                      this.storedRefreshToken = user.refreshToken.token;
                      this.isMasterUser = this._isMasterUser(user.userName);

                      this.startRefreshTokenTimer();
                      this.logInSuccess?.emit();
                      observer.next(true);
                      //return user;
                  }, (error) => {
                      this.reloggingInWithRefreshToken = false;
                      observer.error(error);
                      this.logInFail?.emit();
                      alert(error);
                  }));
              promis.subscribe(
                res=> {},
                err => {
                  this.reloggingInWithRefreshToken = false;
                  observer.error(err);
                  this.logInFail?.emit();
                }
              );
          }
          else
          {
            observer.next(false);

              //return this.ok( {name : ""} );
          }


        }
      );
  }

    getStoredApiKey () : string
    {
      if (this.useCookies == true)
      {
        return this.getCookie(this.apiKeyStoreKeyName);
      }
      else
      {
        return this.getItem(this.apiKeyStoreKeyName);
      }
    }

    setStoredApiKey (apiKey : string)
    {
      if (this.useCookies == true)
      {
        this.setCookie(this.apiKeyStoreKeyName, apiKey);
      }
      else
      {
        this.storeItem(this.apiKeyStoreKeyName, apiKey);
      }
    }

    deleteStoredApiKey ()
    {
      if (this.useCookies == true)
      {
        this.deleteCookie(this.apiKeyStoreKeyName);
      }
      else
      {
        this.deleteStoredItem(this.apiKeyStoreKeyName);
      }
    }


  public updateUser2FaChannel(activeMFAChannel: string, userId: string, twoFactorAuthEnabled): void {
    const storedUserValue = this.getItem(this.userStoreKeyName);
    if (storedUserValue) {
      const user = JSON.parse(storedUserValue) as UserAlchemint;
      if (user && user.id === userId) {
        const twoFactorSettins = { channel: activeMFAChannel, enabled: twoFactorAuthEnabled };
        user.twoFactorAuthSettings = twoFactorSettins;
        this.storeItem(this.userStoreKeyName, JSON.stringify(user));
      }
    }
  }

    StoreUser (us : UserAlchemint, storePassword : boolean) : void
    {
      us.token = null;
      if (this.useCookies == true)
      {
        this.setCookie(this.userStoreKeyName, JSON.stringify(us));
        this.setCookie("storePassword", storePassword.toString());
      }
      else
      {

        (new PasswordEncryption()).encryptPasswordAsync(us.passwordHash,us.id).then(
          x=> {
            us.passwordHash = 'ENC2:' + x;
            this.storeItem(this.userStoreKeyName, JSON.stringify(us));
            this.storeItem("storePassword", storePassword.toString());
          }
        );

        // us.passwordHash = 'ENC2:' + (new PasswordEncryption()).encryptPassword(us.passwordHash,us.id);


        // this.storeItem(this.userStoreKeyName, JSON.stringify(us));
        // this.storeItem("storePassword", storePassword.toString());
      }
    }

    // GetStoredUser () : UserAlchemint
    // {
    //   var json : string;
    //   if (this.useCookies == true)
    //   {
    //     json = this.getCookie(this.userStoreKeyName);

    //   }
    //   else
    //   {
    //     json =  this.getItem(this.userStoreKeyName);
    //   }
    //   if (json)
    //   {

    //     var storedUser : UserAlchemint = JSON.parse(json);

    //     var sp : string = this.getItem("storePassword");
    //     var storePassword : boolean = false;
    //     storePassword = (sp?.toLowerCase() == 'true');
    //     if (storePassword == true)
    //     {
    //       storedUser.passwordHash = this.decryptPaswordIfRequired (storedUser.passwordHash, storedUser.id);
    //     }
    //     else
    //     {
    //       storedUser.passwordHash = '';
    //     }

    //     return storedUser;
    //   }
    //   else
    //   {
    //     return null;
    //   }

    // }
    GetStoredUserWithNoPassword () : UserAlchemint
    {
      
      return this.getStoreLogidInUser();
      
      var json : string;
      if (this.useCookies == true)
      {
        json = this.getCookie(this.userStoreKeyName);

      }
      else
      {
        json =  this.getItem(this.userStoreKeyName);
      }
      if (json)
      {
        var storedUser : UserAlchemint = JSON.parse(json);
        return storedUser;
      }
      else
      {
        return null;
      }

    }


    async GetStoredUserAsync () : Promise<UserAlchemint>
    {
      var json : string;
      if (this.useCookies == true)
      {
        json = this.getCookie(this.userStoreKeyName);

      }
      else
      {
        json =  this.getItem(this.userStoreKeyName);
      }
      if (json)
      {

        var storedUser : UserAlchemint = JSON.parse(json);

        var sp : string = this.getItem("storePassword");
        var storePassword : boolean = false;
        storePassword = (sp?.toLowerCase() == 'true');
        if (storePassword == true)
        {
          storedUser.passwordHash = await this.decryptPaswordIfRequiredAsync (storedUser.passwordHash, storedUser.id);
        }
        else
        {
          storedUser.passwordHash = '';
        }

        return Promise.resolve(storedUser);
      }
      else
      {
        return Promise.resolve(null);
      }

    }


    DeleteStoredUser () : void
    {
      if (this.useCookies == true)
      {
        this.deleteCookie(this.userStoreKeyName);
      }
      else
      {
        this.deleteStoredItem(this.userStoreKeyName);
        this.deleteStoredItem("storePassword");

      }
    }


    private _isMasterUser (userName : string) : boolean
    {
      const MASTER_USER : string = 'master';
      return userName ===  MASTER_USER;
    }

    private storeItem (key : string, value : string)
    {
      if ((value == null) || (value == 'null') || (value == ''))
      {
        localStorage.removeItem(key);
      }
      else
      {
        localStorage.setItem(key,value);
      }
    }

    private getItem (key : string) : string
    {
      var value = localStorage.getItem(key);
      if ((value == 'null') || (value == ''))
      {
        value = null;
      }
      return value;
    }

    private deleteStoredItem (key : string) : void
    {
      localStorage.removeItem(key);
    }
    ok(body?) {
        return of(new HttpResponse({ status: 200, body }))
    }

    ClearAllLocalStorageItems () : void
    {
      this.DeleteStoredUser();
      this.deleteAllCookies();
      this.deleteStoredApiKey();
      localStorage.removeItem(this.storeUserTokenKey);
    }

    public static refreshTokenLogonAttempts: number =0;


    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        if(this.storedBearerToken){
        // parse json object from base64 encoded jwt token
        // const jwtToken = JSON.parse(atob(this.userValue.token.split('.')[1]));
          const jwtToken = JSON.parse(atob(this.storedBearerToken.split('.')[1]));
          // set a timeout to refresh the token a minute before it expires
          const expires = new Date(jwtToken.exp * 1000);
          const timeout = expires.getTime() - Date.now() - (60 * 1000);
          this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }


  private buildQueryParamatersFromEntity <T> (entity : T) : string
  {
    return this.serialize(entity);
  }

  private serialize = function(obj : any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

   private deleteAllCookies() {
    document.cookie = "";
    return;

    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    }


    // returns the cookie with the given name,
// or undefined if not found
 getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(name, value, options : any = {}) {

    // if (options.expires instanceof Date) {
    //   options.expires = options.expires.toUTCString();
    // }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  deleteCookie(name) {
    this.setCookie(name, "", {
      'max-age': -1
    })
  }

  // decryptPaswordIfRequired (pswd : string, key: string) : string
  // {
  //   if (pswd?.startsWith("ENC2:"))
  //   {
  //     var p = pswd.substring(5);
  //     return (new PasswordEncryption()).decryptPassword(p,key);
  //   }
  //   if (pswd?.startsWith("ENC1:"))
  //   {
  //     return pswd.substring(4);
  //   }
  //   else
  //   {
  //     return pswd;
  //   }
  // }

  async decryptPaswordIfRequiredAsync(pswd: string, key: string): Promise<string> {
    if (pswd?.startsWith("ENC2:")) {
      const p = pswd.substring(5);
      const ps: string = await new PasswordEncryption().decryptPasswordAsync(p, key);
      return Promise.resolve(ps);
    }

    if (pswd?.startsWith("ENC1:")) {
      return Promise.resolve(pswd.substring(5));
    } else {
      return Promise.resolve(pswd);
    }
  }


  private apiKeyFileName : string = "ApiKeyFile";

  public getApiKeyFileEntries () : CompanyApiKey []
  {
    let apiKeyFileData : string = localStorage.getItem(this.apiKeyFileName);
    if (apiKeyFileData)
    {
      let apiKeyFileEntries : CompanyApiKey [] = JSON.parse(apiKeyFileData);
      return apiKeyFileEntries;
    }
    else
    {
      return null;
    }
  }

  public pushApiKEyFileEntry (apiKey : string, apiKeyAlias : string) : void
  {
    let apiKeyEntries : CompanyApiKey [] = this.getApiKeyFileEntries ();
    let alreadyExists : boolean = false;

    if (apiKeyEntries)
    {
      var find : CompanyApiKey [] = apiKeyEntries.filter(x => x.Id === apiKey);
      if (find.length > 0)
      {
        alreadyExists = true;
      }
      else
      {
        alreadyExists = false;
      }
    }
    else
    {
      alreadyExists = false;
      apiKeyEntries = [];
    }

    if (alreadyExists === false)
    {
      apiKeyEntries.push(new CompanyApiKey(apiKey, apiKeyAlias));
      localStorage.setItem(this.apiKeyFileName, JSON.stringify(apiKeyEntries));
    }

  }

  setApiKeyFileData(apiKeyFileData: string) {

    try {
      if (apiKeyFileData)
      {
        var data = JSON.parse(apiKeyFileData);
        if (data)
        {
          localStorage.setItem(this.apiKeyFileName, JSON.stringify(data, null, 2));
        }
        else{
          localStorage.removeItem(this.apiKeyFileName);
        }
      }
      else
      {
        localStorage.removeItem(this.apiKeyFileName);
      }

    } catch (error) {
      alert(error);
    }
  }


  // private _betaLicenses : string [] = ['30e8c93a', 'f30904fe'];
  // public isBetaSiteLicense(): boolean {
  //   var result : boolean = false;

  //   this._betaLicenses.forEach(
  //     lic => {
  //       if (this.apiKeyValue?.startsWith(lic))
  //       {
  //         result = true;
  //       }
  //     }
  //   );
  //   return result;
  // }


  public get eSpecialFunctionalOrganizationsType() : eSpecialFunctionalOrganizations
  {
    // return eSpecialFunctionalOrganizations.GuidedTourCompany;

    // if (this.environmentService.env === Environment.Dev)
    // {
    //   return eSpecialFunctionalOrganizations.GuidedTourCompany;
    // }
    
    var apiKeyValue = this.getStoredApiKey();
    
    if (apiKeyValue?.startsWith('49982f92'))
    {
      return eSpecialFunctionalOrganizations.BiotekConf;
    }

    else if (apiKeyValue?.startsWith('ec3063aa'))
    {
      return eSpecialFunctionalOrganizations.MasterDemoDataSet;
    }
    else if (apiKeyValue?.startsWith('49982f92'))
    {
      return eSpecialFunctionalOrganizations.AlchDemoCompany;
    }
    else if (apiKeyValue?.startsWith('SHARED'))
    {
      return eSpecialFunctionalOrganizations.SharedLibrary;
    }
    else if (apiKeyValue?.startsWith('a0d2aec8-e74b'))
    {
      return eSpecialFunctionalOrganizations.None;
      // return eSpecialFunctionalOrganizations.GuidedTourCompany;
    }
    else
    {
      return eSpecialFunctionalOrganizations.None;
    }
  }

  public get isConferenceOrg(): boolean
  {
    if (this.eSpecialFunctionalOrganizationsType === eSpecialFunctionalOrganizations.GuidedTourCompany)
    {
        return true;
    }
    else
    {
      return false;
    }
  }

  public get isBioteckConferenceOrg(): boolean
  {
    if (this.eSpecialFunctionalOrganizationsType === eSpecialFunctionalOrganizations.BiotekConf)
    {
        return true;
    }
    else
    {
      return false;
    }
  }

  

  public eSpecialFunctionalOrganizationsTypeName(mode: eSpecialFunctionalOrganizations) : string
  {

    if (mode === eSpecialFunctionalOrganizations.MasterDemoDataSet)
    {
      return "MASTER DEMO SET";
    }

    if (mode === eSpecialFunctionalOrganizations.AlchDemoCompany)
    {
      return "DEMO COMPANY";
    }

    if (mode === eSpecialFunctionalOrganizations.SharedLibrary)
    {
      return "SHARED LIBRARY";
    }

    return "";
  }

  public emitLogoutEvent() {
    this.requestAppLogout?.emit();
  }
  

}

export enum eSpecialFunctionalOrganizations
{
  None = 0,
  MasterDemoDataSet = 1,
  SharedLibrary = 2,
  AlchDemoCompany = 3,
  GuidedTourCompany = 4,
  BiotekConf
}
export class CompanyApiKey
{
  constructor(public Id : string, public Name : string)
  {

  }
}



// import CryptoJS from 'crypto-js';

export class PasswordEncryption {

  constructor()
  {

  }



  // encryptPassword(password: string, secretKey: string): string {
  //   const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
  //   return encryptedPassword;
  // }

  // decryptPassword(encryptedPassword: string, secretKey: string): string {
  //   const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
  //   const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
  //   return decryptedPassword;
  // }

  public CryptoJSAsync: any;

  async loadCryptoJSAsync() {
    this.CryptoJSAsync = await import('crypto-js');
  }

  async decryptPasswordAsync(encryptedPassword: string, secretKey: string): Promise<string> {
    await this.loadCryptoJSAsync();
    const bytes = this.CryptoJSAsync.AES.decrypt(encryptedPassword, secretKey);
    const decryptedPassword = bytes.toString(this.CryptoJSAsync.enc.Utf8);
    return decryptedPassword;
  }
  async encryptPasswordAsync(password: string, secretKey: string): Promise<string> {
    await this.loadCryptoJSAsync();
    const encryptedPassword = this.CryptoJSAsync.AES.encrypt(password, secretKey).toString();
    return encryptedPassword;
  }
}
